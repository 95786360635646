import React from "react"

import { useFirebase } from "gatsby-plugin-firebase"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"

import Footer from "../components/sections/footer"

import styled from "styled-components"
import { Container } from "../components/global"

const DataProtectionPage = () => {
    
    useFirebase(firebase => { firebase.analytics().logEvent("page_view_imprint") }, [])

    return (
    <Layout>
        <SEO title="apocha" />
        <Navigation />
        <ImprintContainer>
            <h3>Imprint</h3>

            <ImprintSectionHeading>apocha is provided by</ImprintSectionHeading>
			<p>
				apocha UG (haftungsbeschr&auml;nkt)<br />
				Im Brachmoos 12<br />
				88149 Nonnenhorn<br />
                Germany
			</p>
            <ImprintSectionHeading>Contact</ImprintSectionHeading>
            <p>
                Phone: +4915154809866 (no support)<br />
                Email: olaf@apocha.biz (no support)<br />
                <br />
                Please send questions and support requests to support@apocha.app.
            </p>
        </ImprintContainer>
        <Footer />
    </Layout>
)
}

export default DataProtectionPage

const ImprintContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 80px 18px 40px;
`
const ImprintSectionHeading = styled.h5
`
    margin-block-end: 0em;
`
